import React from 'react';
import styles from './about.module.css';
import mailIcon from '../data/img/mail.png';
import instaIcon from '../data/img/insta.png';

export default () => {
    return (
        <div id={styles.container}>
            <div className={styles.box}>
                <div>
                    Patrizia Bürkli (*<span>1997</span>, Luzern)
                </div>
            </div>
            <div className={styles.box}>
                <div>
                    <span></span>
                </div>
            </div>
            <div className={styles.box}>
                <div>
                    Grafikdesignstudentin mit grosser Fantasie, begeistert für Herausforderungen und Typografie. Genauso
                    angetan von Motion und Coding wie von analogen Printprodukten. Nicht talentiert aber verrückt
                    verliebt ins Gestalten.
                </div>
            </div>
            <div className={styles.box}>
                <a href="mailto:hi@patriziabuerkli.ch" target="_blank">
                    <div className={styles.link}>
                        <img src={mailIcon} height="35" />
                        <div className={styles.text}>hi@patriziabuerkli.ch</div>
                    </div>
                </a>
                <a href="https://www.instagram.com/patriziabuerkli/" target="_blank">
                    <div className={styles.link}>
                        <img src={instaIcon} height="35" />
                        <div className={styles.text}>patriziabuerkli</div>
                    </div>
                </a>
            </div>
            <div className={styles.box}>
                <div>
                    <span>2018</span> – jetzt
                </div>
                <div>Visuelle Kommunikation</div>
                <div>Hochschule der Künste, Bern</div>
            </div>
            <div className={styles.box}>
                <div>
                    <span>2016</span> – <span>2018</span>
                </div>
                <div>Gestalterischer Vorkurs</div>
                <div>Schule für Kunst und Design, Zürich</div>
            </div>
            <div className={styles.box}>
            <div className={styles.small}>
            <a href="https://buero.space">Merci an Robin Bürkli für die Webentwicklung der Hauptseite</a>
            </div>
             </div>
        </div>
    );
};
