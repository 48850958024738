import React from 'react';
import cursorSlow from '../data/cursors/slow.svg';
import cursorNormal from '../data/cursors/normal.svg';
import cursorFast from '../data/cursors/fast.svg';

class Project extends React.Component {
    constructor(props) {
        super(props);
        // Create a Ref to later be able to reference the video from code
        // (to control playback)
        this.vidRef = React.createRef();
        this.state = {
            cursorName: 'cursorNormal',
            cursorOffset: 20,
        };
    }

    componentDidMount() {
        // Recalculate video size if the screen gets resized
        window.addEventListener('resize', this.recalculateVideoSize);
        this.fetchProjectResource();
    }

    componentDidUpdate() {
        const { index, activeSection, didSectionChange, sectionChangeProcessed, mousePosition } = this.props;
        if (didSectionChange) {
            if (index === activeSection.index) {
                if (mousePosition) {
                    this.processMouseMovement(mousePosition.x, mousePosition.y);
                }
            }
            sectionChangeProcessed();
        }
    }

    // Grab the whole project video and notify parent when finished 
    fetchProjectResource = () => {
        const { source } = this.props.data;
        fetch('/project-assets/' + source).then((response) => {
            response.blob({ type: 'video/mp4' }).then((blob) => {
                this.vidRef.current.src = URL.createObjectURL(blob);
            });
        });
    };

    // Check whether the aspect ratio of the video is bigger or smaller than
    // the one of the viewport. Set the correct css class accordingly.
    recalculateVideoSize = () => {
        const vid = this.vidRef.current;
        const { videoHeight, videoWidth } = vid;
        const aspectRatioVideo = videoWidth / videoHeight;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const aspectRatioWindow = windowWidth / windowHeight;
        if (aspectRatioWindow < aspectRatioVideo) {
            vid.classList.add('project-video-fullwidth');
            vid.classList.remove('project-video-fullheight');
        }
        if (aspectRatioWindow > aspectRatioVideo) {
            vid.classList.add('project-video-fullheight');
            vid.classList.remove('project-video-fullwidth');
        }
    };

    // Set the playback speed of the current project according to
    // the current mouse position.
    processMouseMovement = (x, y) => {
        this.handleCursorIdleTimeout();
        const vid = this.vidRef.current;
        const vidRect = vid.getBoundingClientRect();
        if (x > vidRect.left && x < vidRect.right) {
            if (y > vidRect.top && y < vidRect.bottom) {
                // mouse hovers video
                if (this.props.data.speedable === true) {
                    // if cursor is in left half of video play normal, fast forward
                    // otherwise (this needs update)
                    let vidWidth = vidRect.right - vidRect.left;
                    let localX = x - vidRect.left;
                    if (localX < vidWidth * 0.25) {
                        this.setCursor(cursorSlow);
                        vid.playbackRate = 0.5;
                    } else if (localX > vidWidth * 0.75) {
                        this.setCursor(cursorFast);
                        vid.playbackRate = 2;
                    } else {
                        this.setCursor(cursorNormal);
                        vid.playbackRate = 1;
                    }
                } else {
                    this.setCursor(cursorNormal);
                    vid.playbackRate = 1;
                }
                vid.play();
            }
        }
    };

    // Hide the cursor when idle for 2 seconds. Called by processMouseMovement.
    handleCursorIdleTimeout = () => {
        if (this.state.cursorTimeout) {
            clearTimeout(this.state.cursorTimeout);
        }
        const hideCursor = () => {
            this.setState({
                cursorName: 'hidden',
            });
        };
        const timeout = setTimeout(hideCursor, 2000);
        this.setState({
            cursorTimeout: timeout,
        });
    };

    // Change the cursor (and determine correct offset)
    setCursor = (cursor) => {
        if (this.state.cursorName !== cursor) {
            let offset;
            if (cursor === cursorNormal) {
                offset = 20;
            } else if (cursor === cursorFast) {
                offset = 25;
            } else if (cursor === cursorSlow) {
                offset = 15;
            }
            this.setState({
                cursorName: cursor,
                cursorOffset: offset,
            });
        }
    };

    render() {
        const projectScale = this.props.data.styles.scale / 100;
        // Prepare inline-css to respect the scale property set in projects.json
        const style = {
            transform: 'scale(' + projectScale + ',' + projectScale + ')',
        };
        if (this.state.cursorName === 'hidden') {
            style.cursor = 'none';
        } else {
            style.cursor =
                'url(' +
                this.state.cursorName +
                ') ' +
                this.state.cursorOffset +
                ' ' +
                this.state.cursorOffset +
                ', auto';
        }
        const { index, notifyProjectVidReady } = this.props;
        return (
            <div className="section">
                <video
                    ref={this.vidRef}
                    style={style}
                    preload={'auto'}
                    muted={this.props.data.muted}
                    disablePictureInPicture
                    loop
                    onMouseLeave={(event) => event.target.pause()}
                    onMouseMove={(event) => this.processMouseMovement(event.clientX, event.clientY)}
                    onDurationChange={(event) => {
                        this.recalculateVideoSize();
                        notifyProjectVidReady(index);
                    }}
                    type="video/mp4"
                ></video>
            </div>
        );
    }
}

export default Project;
