import React from 'react';
import Loadable from '@loadable/component';

const LoadableNav = Loadable(() => import('./nav'));

export default ({ children, navFirstAnchor, navLastAnchor, navDotSize }) => {
    return (
        <div>
            {children}
            <LoadableNav firstAnchor={navFirstAnchor} lastAnchor={navLastAnchor} navDotSize={navDotSize} />
        </div>
    );
};
