import React from 'react';
import Loadable from '@loadable/component';
const LoadableP5Animation = Loadable(() => import('./p5animation'));

export default () => {
    return (
        <div className="loading-screen">
            <div id="loading-animation-container">
                <LoadableP5Animation />
            </div>
        </div>
    );
};
