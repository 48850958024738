import React from 'react';
import { graphql } from 'gatsby';
import ReactFullpage from '@fullpage/react-fullpage';
import Project from '../components/project.js';
import Layout from '../components/layout.js';
import About from '../components/about.js';
import LoadingScreen from '../components/loadingscreen.js';
import SEO from '../components/seo.js';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.mainRef = React.createRef();
        // Destruct the data object for simpler usage
        const { projects } = props.data.dataJson;
        const { siteMetadata } = props.data.site;
        // Set the background color for all project pages
        let sectionsColor = [];
        let anchors = [];
        for (let i = 0; i < projects.length; i++) {
            sectionsColor.push(projects[i].styles.background_color);
            anchors.push(projects[i].anchor);
        }
        // Set up about page
        sectionsColor.push(siteMetadata.about_background_color);
        anchors.push('about');
        // Create array to keep track of projects ready state
        let readyProjects = [];
        for (let i = 0; i < projects.length; i++) {
            readyProjects.push(false);
        }

        this.state = {
            sectionsColor: sectionsColor,
            anchors: anchors,
            showLoadingScreen: true,
            readyProjects: readyProjects,
            fullpageSectionLoaded: false,
            fullpageShouldRebuild: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // set fullpageShouldRebuild to false, if it was set to true
        // works like this: something sets fullpageShouldRebuild to true -> triggers re-rendering
        // -> after complete cdu sets fullpageShouldRebuild back to false
        if (this.state.fullpageShouldRebuild) {
            this.setState({
                fullpageShouldRebuild: false
            });
        }
    }

    processMouseMovement = event => {
        const pos = {
            x: event.clientX,
            y: event.clientY
        };
        // const prevPos = this.state.mousePosition;
        this.setState({
            mousePosition: pos
        });
    };

    // Called by project components if their video is ready to play
    // Uses state to keep track of which projects are ready and sets
    // 'showLoadingScreen' to false if everything is ready.
    handleProjectVidReady = index => {
        if (this.state.showLoadingScreen) {
            const { readyProjects } = this.state;
            let newReadyProjects = [];
            let newShowLoadingScreen = false;
            for (let i = 0; i < readyProjects.length; i++) {
                if (i === index) {
                    newReadyProjects.push(true);
                } else {
                    newReadyProjects.push(readyProjects[i]);
                    if (readyProjects[i] === false) {
                        newShowLoadingScreen = true;
                    }
                }
            }
            this.setState({
                showLoadingScreen: newShowLoadingScreen,
                readyProjects: newReadyProjects,
                fullpageShouldRebuild: !newShowLoadingScreen
            });
        }
    };

    // Set fullpageSectionLoaded to true after a new section was successfully loaded
    // Project component uses this state element.
    afterProjectSectionLoad = (section, origin, destination, direction) => {
        this.setState({
            fullpageSectionLoaded: true
        });
    };
    // Used by project component to acknowledge the section load (see above)
    projectAcknowlegedSectionLoad = () => {
        if (this.state.fullpageSectionLoaded) {
            this.setState({
                fullpageSectionLoaded: false
            });
        }
    };

    render() {
        const { projects } = this.props.data.dataJson;
        const { siteMetadata } = this.props.data.site;
        const { sectionsColor, anchors, showLoadingScreen } = this.state;
        let loadingScreen = null;
        let activeSection = null;
        // magic happens here
        if (showLoadingScreen) {
            loadingScreen = <LoadingScreen />;
        }
        // Return a div containing Fullpage, which creates a new Project instance
        // for each project found in the data object
        return (
            <div onMouseMove={this.processMouseMovement}>
                {loadingScreen}
                <Layout navFirstAnchor={projects[0].anchor} navLastAnchor={'about'} navDotSize={siteMetadata.navDotSize}>
                    <SEO />
                    <ReactFullpage
                        //fullpage options
                        licenseKey={'332D2C2D-B84E4F01-88F918FB-C1F7C088'}
                        scrollingSpeed={siteMetadata.scrollingSpeed}
                        sectionsColor={sectionsColor}
                        anchors={anchors}
                        afterLoad={this.afterProjectSectionLoad}
                        render={({ state, fullpageApi }) => {
                            if (fullpageApi) {
                                activeSection = fullpageApi.getActiveSection();
                                if (showLoadingScreen) {
                                    fullpageApi.setAllowScrolling(false);
                                } else {
                                    fullpageApi.setAllowScrolling(true);
                                }
                                if (this.state.fullpageShouldRebuild) {
                                    fullpageApi.reBuild();
                                }
                            }
                            return (
                                // Important: The wrapper must contain at least one element of css class section.
                                // Otherwise strange errors are thrown.
                                <ReactFullpage.Wrapper>
                                    {// Loop through every project found in the data object and create Project instance for it
                                    projects.map((project, index) => (
                                        <Project
                                            key={index}
                                            index={index}
                                            notifyProjectVidReady={this.handleProjectVidReady}
                                            data={project}
                                            didSectionChange={this.state.fullpageSectionLoaded}
                                            sectionChangeProcessed={this.projectAcknowlegedSectionLoad}
                                            mousePosition={this.state.mousePosition}
                                            activeSection={activeSection}
                                        />
                                    ))}
                                    <div className="section">
                                        <About />
                                    </div>
                                </ReactFullpage.Wrapper>
                            );
                        }}
                    />
                </Layout>
            </div>
        );
    }
}
export default App;

// Query all projects data and some metadata (title, background color, ...)
export const query = graphql`
    query projects {
        dataJson {
            projects {
                description
                name
                anchor
                source
                speedable
                muted
                styles {
                    background_color
                    scale
                }
            }
        }
        site {
            siteMetadata {
                title
                about_background_color
                scrollingSpeed
                navDotSize
            }
        }
    }
`;
